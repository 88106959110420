import './Checkout.scss';
import Button from 'components/Button';
import Empty from 'components/Empty';
import Gesture from 'components/Gesture';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Obx from 'components/Obx';
import { getAssetUrl } from 'core/constants/assets-url';
import { getPrice } from 'core/constants/price';
import { CheckoutService } from 'core/services/checkout.service';
import state from 'core/state';
import { Actions } from 'core/state/actions';
import { IVariant, IVariantCartItem } from 'interfaces/variant';
import { classNames, linkTo, listen, provide, ref, signal } from '@duox/mix';

const Checkout = () => {
  const contentRef = ref();
  const backdropRef = ref();
  const checkoutRef = ref();
  const createOrderButtonRef = ref<HTMLButtonElement>();
  const checkoutService = provide(CheckoutService);
  const cart$ = state.select<IVariantCartItem[]>('cart');
  const total = signal(0);

  const onCloseClick = () => {
    contentRef.current.classList.add('over');
    backdropRef.current.classList.add('over');
    setTimeout(() => {
      checkoutService.setVisibility(false);
      backdropRef.current.classList.add('hide');
    }, 700);
  };

  const onDeleteItem = (variant: IVariant) => {
    // store.dispatch(StoreActions.DELETE_CART_ITEM, variant);
    state.dispatch(Actions.removeItem, { variant }, ['cart']);
  };

  checkoutService.isEnabled$.subscribe((isEnabled) => {
    if (isEnabled) {
      contentRef.current.classList.remove('over');
      backdropRef.current.classList.remove('over');
      backdropRef.current.classList.remove('hide');

      checkoutRef.current.classList.remove('closed');
      checkoutRef.current.classList.add('open');
    } else {
      checkoutRef.current.classList.remove('open');
      checkoutRef.current.classList.add('closed');
    }
  });

  const items$ = listen((data) => {
    total.set(0);

    return data.length ? (
      <div className="list">
        {data.map(({ quantity, variant }) => {
          const currentTotal = +variant.product.price * quantity;
          total.set(total.get() + currentTotal);

          return (
            <Gesture
              onClick={() =>
                linkTo(`catalogue/${variant.product.id}`, {
                  triggerScroll: true,
                  scrollTime: 100,
                })
              }
            >
              <div className="cart-item">
                <item-information>
                  <div className="cart-item__media">
                    {variant.assets.length && (
                      <Image src={getAssetUrl(variant.assets[0])} isLoading />
                    )}
                  </div>
                  <div className="cart-item__information">
                    <p className="cart-item__information__name">
                      {variant.product.name}
                    </p>
                    <props>
                      {variant.variantPropertyDetails.map(
                        ({ variantProperty }) => (
                          <p className="cart-item__information__property">
                            <b>{variantProperty.name}</b>{' '}
                            {variantProperty.value}
                          </p>
                        ),
                      )}
                    </props>
                    <div className="cart-item__information__price">
                      <p>
                        {`$${getPrice(
                          variant.product.price,
                          variant.product.discountPercent,
                          variant.overridePrice,
                        )}`}{' '}
                        x {String(quantity)}
                      </p>
                    </div>
                  </div>
                </item-information>
                <div className="cart-item__action">
                  <Button
                    onClick={(event) => {
                      onDeleteItem(variant);
                      event.stopPropagation();
                    }}
                  >
                    <Icon name="delete-bin" />
                  </Button>
                </div>
              </div>
            </Gesture>
          );
        })}
      </div>
    ) : (
      <Empty message="No has agregado nada aún" />
    );
  }, cart$);

  const total$ = listen((totalValue) => {
    return (
      <div className="checkout-content-group">
        <div className="checkout__content__actions__total">
          <p>Total</p>
          <p className="price">{`$${totalValue.toFixed(2)}`}</p>
        </div>
        <div className="checkout__content__actions__cta">
          {totalValue > 0 && (
            <Button
              ref={createOrderButtonRef}
              className="cta-button-primary"
              onClick={() => {
                linkTo('checkout');
                onCloseClick();
              }}
            >
              Finalizar compra
            </Button>
          )}

          <Button className="cta-button-secondary" onClick={onCloseClick}>
            Continuar comprando
          </Button>
        </div>
      </div>
    );
  }, total);

  return (
    <div className={classNames('checkout', 'closed')} ref={checkoutRef}>
      <div className="checkout__backdrop" ref={backdropRef} />
      <div className="checkout__content" ref={contentRef}>
        <div className="checkout__content__primary">
          <div className="checkout__content__primary__heading">
            <div className="checkout__content__primary__heading__blurb">
              <p className="checkout__content__primary__heading__blurb__title">
                Tu carrito
              </p>
              <p className="checkout__content__primary__heading__blurb__blurb">
                Acá se mostrarán los productos que vayas agregando.
              </p>
            </div>
            <Button
              className="checkout__content__primary__heading__close"
              onClick={onCloseClick}
            >
              <Icon name="close" />
            </Button>
          </div>
          <div className="container">
            <Obx name="cart-list-items" observable={items$} isSignal />
          </div>
        </div>

        <div className="checkout__content__actions">
          <Obx name="variant-total" observable={total$} isSignal />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
