import { IAppState } from 'core/services/store/state';
import { provide, Store } from '@duox/mix';

export const appState: IAppState = {
  loading: false,
  addressCities: [],
  addresses: [],
  addressStates: [],
  orders: [],
  products: [],
  cart: [],
  currentOrder: null,
  user: null,
};

const state = provide<Store<IAppState>>(Store);

export default state;
