export const getPrice = (
  price: string | number,
  discountPercent = 0,
  replaceWith?: string | number,
): string => {
  const value = +replaceWith > 0 ? replaceWith : price;

  if (discountPercent > 0) {
    return (Number(value) * (discountPercent / 100)).toFixed(2);
  }

  return (+value).toFixed(2);
};
