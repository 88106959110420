import { fromEvent } from '@duox/mix';

type TProps = {
  onClick: (event: Event) => void;
  trigger?: boolean;
  children?: HTMLElement;
};

const Gesture = ({ onClick, children, trigger = true }: TProps) => {
  if (trigger) {
    fromEvent(children, 'click').subscribe(onClick);
  }

  return <>{children}</>;
};

export default Gesture;
