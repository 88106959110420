import './Footer.scss';
import Gesture from 'components/Gesture/Gesture';
import Icon from 'components/Icon';
import { linkTo } from '@duox/mix';

const Footer = () => (
  <div className="footer">
    <div className="wrapper">
      <div className="footer__presentation">
        <Gesture onClick={() => linkTo('/', { triggerScroll: true })}>
          <div className="footer__presentation__blurb">
            <p>Fashion &amp;</p>
            <p>Love</p>
            <p className="year">2024</p>
          </div>
        </Gesture>
      </div>
      <div className="footer__content">
        <div className="footer__content__terms">
          <Gesture
            onClick={() =>
              linkTo('about/terms-and-conditions', { triggerScroll: true })
            }
          >
            <a>Términos y condiciones</a>
          </Gesture>
          <Gesture
            onClick={() =>
              linkTo('about/privacy-policy', { triggerScroll: true })
            }
          >
            <a>Política de privacidad</a>
          </Gesture>
        </div>
        <div className="footer__content__social">
          <div className="footer__content__social--network">
            <a
              className="icon"
              href={process.env.FACEBOOK_URL}
              target="_blank"
              aria-label="Facebook"
            >
              <Icon name="facebook-box" isLine={false} />
            </a>
            <a
              className="icon"
              href={process.env.INSTAGRAM_URL}
              target="_blank"
              aria-label="Instagram"
            >
              <Icon name="instagram" isLine={false} />
            </a>
            <a
              className="icon"
              href={process.env.WHATSAPP_URL}
              target="_blank"
              aria-label="WhatsApp"
            >
              <Icon name="whatsapp" isLine={false} />
            </a>
          </div>
          <a href="mailto:support@fashy.love" target="_blank">
            support@fashy.love
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
