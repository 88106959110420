import Gesture from 'components/Gesture';
import { ComponentRef } from '@duox/mix';

type TProps = {
  children?: string | HTMLElement | HTMLElement[];
  className?: string;
  onClick: (event: Event) => void;
  name?: string;
  ref?: ComponentRef<HTMLButtonElement>;
};

const Button = ({ onClick, className, children, ref, name }: TProps) => {
  return (
    <Gesture onClick={onClick}>
      <button ref={ref} className={className} aria-label={name}>
        {children}
      </button>
    </Gesture>
  );
};

export default Button;
