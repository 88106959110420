import { attachElementId, Observable, observe } from '@duox/mix';

type TProps<T> = {
  name: string;
  observable: Observable<T>;
  resolver?: (data: T) => HTMLElement;
  isSignal?: boolean;
};

const Obx = <T,>({ name, observable, resolver, isSignal }: TProps<T>) => {
  const base = isSignal
    ? observe(observable as Observable<HTMLElement>)
    : observe(observable.pipe(resolver));

  base.setAttribute('id', name);

  return attachElementId(base, 'obxContainerId', name);
};

export default Obx;
