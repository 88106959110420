import { from, injectable } from '@duox/mix';

@injectable
export class CheckoutService {
  isEnabled$ = from(false);

  setVisibility(visibility: boolean) {
    this.isEnabled$.emit(visibility);
  }
}
