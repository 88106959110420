import { IAppState } from 'core/services/store/state';
import { IAddress } from 'interfaces/addresses';
import { IOrder } from 'interfaces/order';
import { IProduct } from 'interfaces/product';
import { IUser } from 'interfaces/user';
import { IVariant, IVariantCartItem } from 'interfaces/variant';
import { ReducersMap } from '@duox/mix';
import { Actions } from './actions';

const appReducers: ReducersMap<IAppState> = {
  [Actions.getUser]: (state, { user }: { user: IUser }) => ({ ...state, user }),
  [Actions.getProducts]: (state, { products }: { products: IProduct[] }) => ({
    ...state,
    products,
  }),
  [Actions.getAddresses]: (state) => ({ ...state }),
  [Actions.setItem]: (state, { item }: { item: IVariantCartItem }) => ({
    ...state,
    cart: [...state.cart, item],
  }),
  [Actions.removeItem]: (state, { variant }: { variant: IVariant }) => ({
    ...state,
    cart: state.cart.filter(({ variant: { id } }) => variant.id !== id),
  }),
  [Actions.setAddresses]: (
    state,
    { addresses }: { addresses: IAddress[] },
  ) => ({
    ...state,
    addresses,
  }),
  [Actions.setOrders]: (state, { orders }: { orders: IOrder[] }) => ({
    ...state,
    orders,
  }),
};

export default appReducers;
