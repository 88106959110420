import './App.scss';
import { AuthService } from 'core/services/auth.service';
import { provide, Route } from '@duox/mix';
import Checkout from './Checkout';
import Routes from './Routes';

const routes: Route[] = [
  {
    path: '',
    component: import('./Home'),
  },
  {
    path: 'catalogue/:id',
    component: import('./Details'),
  },
  {
    path: 'checkout',
    component: import('./Checkout/Payment'),
  },
  {
    path: 'auth',
    component: import('./Auth'),
    canActivate: () => provide(AuthService).onUnauthenticatedGuard(),
  },
  {
    path: 'me',
    component: import('./Dashboard'),
    canActivate: () => provide(AuthService).onAuthenticatedGuard(),
    children: [],
  },
  {
    path: 'about',
    children: [
      {
        path: 'terms-and-conditions',
        component: import('./PrivacyAndConditions/TermsAndConditions'),
      },
      {
        path: 'privacy-policy',
        component: import('./PrivacyAndConditions/Privacy'),
      },
    ],
  },
  {
    path: '*',
    component: import('./NotFound'),
  },
];

const App = () => (
  <section className="main-layout">
    <Checkout />
    <Routes name="app" routes={routes} />
  </section>
);

export default App;
