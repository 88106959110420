import './Empty.scss';
import Icon from 'components/Icon';

type TProps = {
  message: string;
};

const Empty = ({ message }: TProps) => (
  <div className="empty">
    <Icon name="emotion-sad" isLine={false} />
    <p>{message}</p>
  </div>
);

export default Empty;
