import axios from 'core/fetch';
import { IApiResponse } from 'interfaces/api-response';
import { injectable, linkTo } from '@duox/mix';

type LoginResponse = {
  accessToken: string;
  created: boolean;
};

@injectable
export class AuthService {
  async login(credential: string) {
    try {
      const {
        data: { data },
      } = await axios.post<IApiResponse<LoginResponse>>('auth', {
        credential,
      });

      return data;
    } catch (ex) {
      localStorage.clear();
      linkTo('auth');
    }
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken') ?? null;
    return token && token.startsWith('ey') && token.length > 100;
  }

  onAuthenticatedGuard(): boolean {
    const isAuthenticated = this.isAuthenticated();

    if (!isAuthenticated) {
      setTimeout(() => linkTo('auth'), 100);
    }

    return isAuthenticated;
  }

  onUnauthenticatedGuard(): boolean {
    const isAuthenticated = this.isAuthenticated();

    if (isAuthenticated) {
      linkTo('me/info');
    }

    return !isAuthenticated;
  }
}
