import { Route, routing } from '@duox/mix';

type TProps = {
  name: string;
  routes: Route[];
};

const Routes = ({ name, routes }: TProps) => (
  <router-outlet>{routing(name, routes)}</router-outlet>
);

export default Routes;
