import { createFetch, linkTo } from '@duox/mix';

const axiosInstance = createFetch(process.env.API_URL);

axiosInstance.interceptors.request.use(
  (request) => {
    request.headers.setAuthorization(
      `Bearer ${localStorage.getItem('accessToken')}`,
    );

    return request;
  },
  (error) => error,
);

axiosInstance.interceptors.response.use(
  (value) => value,
  (error) => {
    const status = error?.['response']?.['status'] ?? null;

    if (status === 401) {
      localStorage.clear();
      linkTo('auth');
    }

    return error;
  },
);

export default axiosInstance;
