import { classNames } from '@duox/mix';

type TProps = {
  name: string;
  isLine?: boolean;
  className?: string;
};

const Icon = ({ name, className, isLine = true }: TProps) => (
  <i
    className={classNames(`ri-${name}-${isLine ? 'line' : 'fill'}`, className)}
  ></i>
);

export default Icon;
