import App from 'components/App';
import Footer from 'components/Footer';
import appReducers from 'core/state/reducers';
import { appState } from 'core/state/state';
import { createState, render } from '@duox/mix';

const content = document.querySelector('#app') as HTMLElement;
const footer = document.querySelector('footer') as HTMLElement;

const bootstrap = async () => {
  createState(appState, appReducers);

  await render(App, content);
  await render(Footer, footer);
};

bootstrap();
