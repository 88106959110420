import './Image.scss';
import { classNames } from '@duox/mix';

type TProps = {
  src: string | null;
  alt?: string;
  isLoading?: boolean;
  className?: string;
};

const Image = ({ src, alt, isLoading = false, className }: TProps) => {
  if (!isLoading) {
    return <img className={className} src={src} alt={alt} loading="lazy" />;
  }

  return (
    <img
      className={classNames('lazy-image', className)}
      src={src}
      alt={alt}
      loading="lazy"
    />
  );
};

export default Image;
